// Module
import * as React from "react";

import { withTranslation, WithTranslation } from 'react-i18next';
import { t } from "i18next";
// Style
import "./hero.scss";
import CustomButton from "src/components/Atoms/CustomButton/customButton";

export interface IHeaderStyle {
    backgroundImage: string;
    backgroundPosition: string;
    backgroundSize: string;
    height?: number;
}

export interface IHeroProps {
    style: IHeaderStyle;
    header: string | JSX.Element;
    subLine: string;
    buttonLink?: string;
    isBlog?: boolean;
}
interface IProps extends WithTranslation {
    prop: any;
}

interface IState {
    state: any;
}
export const headerStyle = (imgLoc: string): IHeaderStyle => ({
    backgroundImage:
        "linear-gradient(180deg, #ffffff 0.42%, rgba(85, 72, 100, 0.3) 90.01%), url(" +
        imgLoc +
        ")",
    backgroundPosition: "center",
    backgroundSize: "cover"
});

class Hero extends React.PureComponent<IHeroProps,IProps , IState> {
    public render() {
        // console.log(this.props.header);
        // console.log(this.props.subLine);
        // console.log(this.props.isBlog);
        
        return (
            <header>
                <div
                    className="hero d-flex align-items-center"
                    style={this.props.style}
                >
                    <div className="container px-3">
                        <h1 className="hero__header text-light">
                        {/* {typeof this.props.header === "string" ? t(`hero.${this.props.header}.title`) : ""} */}
                        {/* {  t(`hero.${this.props.header}.title`)  ?  this.props.header : t(`hero.${this.props.header}.title`)} */}
                        { this.props.isBlog ? this.props.header : t(`hero.${this.props.header}.title`)}
                        {/* { this.props.header } */}
                        {/* {(!!this.props.header.props.dangerouslySetInnerHTML.__html as any)? } */}
                            
                        </h1>

                        {this.props.subLine ? (
                            <p className="hero__subheader body-short-texts text-light">
                                { this.props.isBlog ? this.props.header : t(`hero.${this.props.header}.subTitle`)}
                                {/* { this.props.subLine } */}
                            </p>
                        ) : null}
                        {this.props.buttonLink && (
                            <CustomButton
                                link={this.props.buttonLink}
                                variation="theme"
                            />
                        )}
                    </div>
                </div>
            </header>
        );
    }
}
export default withTranslation('info')(Hero)