import * as React from "react";

import CompanyIncorporation from 'src/containers/3a-companyIncorporation/companyIncorporation';

export default (props: any) => {
  return <CompanyIncorporation {...props} />;
}

export const query = graphql`
  query CompanyIncorporationQuery {
    allWordpressWpPricing {
      edges {
        node {
          acf {
            fee
          }
          title
        }
      }
    }
  }
`