import * as React from "react";

import "./subscription.scss";

import SubscribeInputBox from "src/components/Molecules/SubscribeInput/SubscribeInput";

import { withTranslation, WithTranslation } from 'react-i18next';
import { t } from "i18next";

interface IProps extends WithTranslation {
    prop: any;
  }
  
  interface IState {
    state: any;
  }


class Subscription extends React.Component<{},IProps , IState> {
    constructor(props: {}) {
        super(props);

        // this.state = {
        //     subEmail: ""
        // };
    }

    // public handleFormChange = (e: any) => {
    //     this.setState({
    //         state: e.target.value
    //     });
    // };

    public render() {
        return (
            <div className="container-fluid section-padding-tb96">
                <div className="container footer-upper ">
                    <h3 className="section-header">
                        {t(`Subscription.BE-IN-THE`)} <b className="text-highlight">{t(`Subscription.FIRST`)}</b> {t(`Subscription.TO-KNOW`)}
                    </h3>
                    <div className="row">
                        <div className="col-md-5 col-lg-7">
                            <p>
                                {t(`Subscription.description`)}
                            </p>
                        </div>
                        <div className="col-md-7 col-lg-5">
                            <div id="mc_embed_signup">
                                <SubscribeInputBox />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export default withTranslation('info')(Subscription)