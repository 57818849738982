// Module
import * as React from "react";
import { Link } from "gatsby"

// Style
import "./customButton.scss";

// Components
import Button from "reactstrap/lib/Button";

import { withTranslation, WithTranslation } from 'react-i18next';
import { t } from "i18next";
// Variation of the button
// 1. Trigger <Link>
// 2. Hyperlink to external link
// 3. Trigger a custom function
// 4. A button

interface ICustomButtonProps {
    text?: string;
    fnTrigger?: any;
    key?: string;
    id?: string;
    link: string;
    onClick? : any;
    variation: "primitive" | "theme" | "affirmative" | "alertwarning";
}
interface IProps extends WithTranslation {
    prop: any;
  }
  
  interface IState {
    state: any;
  }

class CustomButton extends React.PureComponent<
    ICustomButtonProps,IProps , IState
    > {
    public button() {
        return (
            <Button
                className={"custom-button" + " " + this.props.variation}
                onClick={this.props.fnTrigger}
                key={this.props.key ? this.props.key : ""}
                id={this.props.id}
            >
                <span className="button-text text-light">
                    {this.props.text ? this.props.text : t(`btn`)}
                </span>
            </Button>
        );
    }
    public render() {
        let buttonType: "int-link" | "ext-href" | "primitive";
        if (
            this.props.link.match("^http") ||
            this.props.link.match("^mailto")
        ) {
            buttonType = "ext-href";
        } else if (this.props.link.match("^/")) {
            buttonType = "int-link";
        } else {
            buttonType = "primitive";
        }

        return (
            <>
                {buttonType === "ext-href" && (
                    <a href={this.props.link}>{this.button()}</a>
                )}
                {buttonType === "int-link" && (
                    <Link to={this.props.link}>{this.button()}</Link>
                )}
                {buttonType === "primitive" && this.button()}
            </>
        );
    }
}

export default withTranslation('info')(CustomButton)